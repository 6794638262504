<template>
  <div id="landing-layout-root">
    <div id="landing-wrapper">
      <div id="ll-sidebar">
        <router-view name="sidebar" />
      </div>
      <b-container class="landing-main-view">
        <router-view />
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "landing-layout",
};
</script>

<style lang="scss" scoped>
#landing-wrapper {
  display: flex;
  min-height: 100vh;
}
#ll-sidebar {
  width: toRem(440);
  background-color: rgba(54, 135, 255, 0.15);
  padding: toRem(45);
  min-height: 100vh;
}

@media screen and (max-width: $md) {
  #landing-wrapper {
    flex-wrap: wrap;
    align-items: flex-start;
  }
  #ll-sidebar {
    width: 100%;
    min-height: unset;
  }
}
</style>