<template>
  <sidebars-layout :showRightBar="false">
    <template v-slot:left>
      <profile-navigation />
    </template>
    <router-view />
  </sidebars-layout>
</template>

<script>
import ProfileNavigation from "../../components/navs/ProfileNavigation.vue";
import SidebarsLayout from "../../layouts/SidebarsLayout.vue";
export default {
  name: "user-profile",
  components: {
    SidebarsLayout,
    ProfileNavigation,
  },
};
</script>
