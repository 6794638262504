<template>
  <li class="reg-reason">
    <check-icon :size="14" /><span>{{ reason }}</span>
  </li>
</template>

<script>
import CheckIcon from "vue-material-design-icons/CheckCircleOutline.vue";
export default {
  name: "register-reason",
  components: { CheckIcon },
  props: {
    reason: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.reg-reason {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  .check-circle-outline-icon {
    display: flex;
    margin-right: toRem(3);
    svg {
      fill: $greenCandle;
    }
  }
}
</style>