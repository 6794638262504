<template>
  <div>
    <validation-observer v-slot="{ handleSubmit }" v-if="step === 0">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row cols="1">
          <b-col>
            <validation-provider
              v-slot="{ failed, errors }"
              rules="required|email"
              mode="eager"
            >
              <simple-form-input
                v-model="email"
                label="E-mail"
                :hasError="failed"
                :helperText="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-button type="submit">{{
              $t("changePasswordForm.requestReset")
            }}</b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>
    <b-row v-else cols="1">
      <b-col>
        <p>{{ `${$t("changePasswordForm.resetWarning")} ${email}?` }}</p>
        <div class="d-flex justify-content-between">
          <b-button @click="setStep(0)"> {{ $t("general.no") }} </b-button>
          <b-button
            @click="handleRequestPasswordReset"
            :disabled="isRequesting"
          >
            <b-spinner small v-if="isRequesting" />
            {{ $t("general.yes") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MainAlert from "../alerts/MainAlert.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { mapActions } from "vuex";
export default {
  components: { SimpleFormInput },
  name: "reset-password-form",
  data() {
    return {
      email: "",
      step: 0,
      isRequesting: false,
    };
  },
  methods: {
    ...mapActions(["requestPasswordReset"]),
    setStep(num) {
      this.step = num;
      if (num === 0) this.email = "";
    },
    onSubmit() {
      this.step = 1;
    },
    handleRequestPasswordReset() {
      this.isRequesting = true;
      this.requestPasswordReset({ email: this.email })
        .then(() => {
          this.$emit("succeeded");
          this.$toast.success({
            component: MainAlert,
            props: {
              message: "Successfully requested password reset",
            },
          });
        })
        .catch((err) =>
          this.$toast.error({
            component: MainAlert,
            props: {
              message: err.response.data.detail,
            },
          })
        )
        .finally(() => (this.isRequesting = false));
    },
  },
};
</script>

<style>
</style>