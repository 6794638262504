<template>
  <validation-observer v-slot="{ handleSubmit }" ref="registrationObserver">
    <form @submit.prevent="handleSubmit(handleRegister)">
      <b-row cols="1">
        <b-col>
          <validation-provider
            v-slot="{ failed, errors }"
            rules="required|email"
            mode="eager"
            name="email"
          >
            <simple-form-input
              :hasError="failed"
              :helperText="errors[0]"
              :label="$t('general.email')"
              v-model="email"
              type="email"
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            v-slot="{ failed, errors }"
            :rules="{
              required: true,
              min: 8,
              regex: PASSWORD_REGEX,
            }"
            name="password1"
            mode="eager"
          >
            <simple-form-input
              :hasError="failed"
              :helperText="errors[0]"
              :label="$t('general.password')"
              v-model="password1"
              type="password"
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            rules="min:8|required|password:@password1"
            v-slot="{ errors, failed }"
            name="password2"
            mode="eager"
          >
            <simple-form-input
              type="password"
              v-model="password2"
              :label="$t('registrationForm.password2Label')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            :rules="{ required: { allowFalse: false } }"
            v-slot="{ errors }"
          >
            <b-form-checkbox
              required
              v-model="termsAgreed"
              :state="errors.length !== 0 ? false : null"
              class="register-checkbox"
            >
              {{ $t("registrationForm.IAgreeWith") }}
              <router-link to="/termsAndConditions" target="_blank">{{
                $t("registrationForm.TOC")
              }}</router-link>
              {{ $t("registrationForm.and") }}
              <router-link to="/privacyPolicy" target="_blank"
                >{{ $t("registrationForm.privacyPolicy") }}.</router-link
              >
            </b-form-checkbox>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row cols="1">
        <b-col class="d-flex justify-content-center">
          <b-button
            type="submit"
            :disabled="isRegistering"
            id="registration-button"
          >
            <b-spinner small v-if="isRegistering" />
            <span> {{ $t("registrationForm.registerButton") }} </span>
          </b-button>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <b-button variant="primary" :disabled="isRegistering" to="/">
            <span> {{ $t("general.logIn") }} </span>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { PASSWORD_REGEX } from "../../utils/constants";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { mapActions } from "vuex";
export default {
  components: { SimpleFormInput },
  name: "registration-form",
  data() {
    return {
      email: "",
      password1: "",
      password2: "",
      termsAgreed: false,
      isRegistering: false,
      PASSWORD_REGEX,
    };
  },
  methods: {
    ...mapActions(["register"]),
    handleRegister() {
      this.isRegistering = true;
      this.$gtag.event("sign_up");
      this.register({
        email: this.email,
        password1: this.password1,
        password2: this.password2,
      })
        .then(() => this.$emit("succeeded"))
        .catch((err) =>
          this.$refs.registrationObserver.setErrors(err.response.data)
        )
        .finally(() => (this.isRegistering = false));
    },
  },
};
</script>


<style lang="scss" scoped>
.custom-checkbox.register-checkbox {
  a {
    margin: 0 3px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  a {
    color: #dc3545;
  }
}
.btn-primary {
  margin-top: toRem(20);
}
</style>