<template>
  <div class="landing-sidebar-content">
    <div class="claims-area">
      <router-link to="/">
        <ANPLogo class="anp-logo" />
      </router-link>
      <h1>{{ $t("landingSidebar.claim") }}</h1>
    </div>
    <img :src="image" class="ll-infographics" />
  </div>
</template>

<script>
import ANPLogo from "../../assets/img/ANP_logo_col.svg";
export default {
  name: "Landing-sidebar",
  components: { ANPLogo },
  props: {
    image: {
      type: String,
      description: "Image displayed in the lower part of sidebar",
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .claims-area {
    margin-top: toRem(116);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      height: toRem(75);
      width: 100%;
      text-align: center;
    }
  }
  .anp-logo {
    max-width: 276px;
  }
  h1 {
    max-width: 256px;
    margin-top: toRem(36);
    font-size: toRem(34);
    color: #3687ff;
  }
  @media screen and (max-width: $md) {
    h1,
    .ll-infographics {
      display: none;
    }
    .claims-area {
      margin: 0;
    }
  }
}
</style>