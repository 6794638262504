var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogIn)}}},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|email","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"hasError":failed,"helperText":errors[0],"label":_vm.$t('general.email'),"type":"email","data-cy":"input-login","autocomplete":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"hasError":failed,"helperText":errors[0],"label":_vm.$t('general.password'),"type":"password","data-cy":"input-password","autocomplete":"current-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('router-link',{attrs:{"to":"/forgottenPassword"}},[_vm._v(_vm._s(_vm.$t("loginForm.forgotPassword")))])],1),_c('b-col',{staticClass:"login-controls"},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.isLoggingIn,"data-cy":"button-login"}},[(_vm.isLoggingIn)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general.logIn"))+" ")],1),_c('b-button',{attrs:{"variant":"primary","to":"/register","data-cy":"button-registration"}},[_vm._v(" "+_vm._s(_vm.$t("loginForm.register"))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }