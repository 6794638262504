<template>
  <b-row class="center-aligning-row">
    <b-col cols="12" md="8" lg="5" v-if="!succeeded">
      <h2>{{ $t("forgottenPassword.sectionTitle") }}</h2>
      <reset-password-form @succeeded="onSuccess" />
    </b-col>
    <b-col cols="12" md="8" lg="5" v-else>
      <h2>{{ $t("forgottenPassword.successLine1") }}</h2>
      <p>{{ $t("forgottenPassword.successLine2") }}</p>
      <p>
        <b>{{ $t("forgottenPassword.successLine3") }}</b>
      </p>
    </b-col>
  </b-row>
</template>

<script>
import ResetPasswordForm from "../components/forms/ResetPasswordForm.vue";
export default {
  components: { ResetPasswordForm },
  name: "Forgotten-password",
  data() {
    return {
      succeeded: false,
    };
  },
  methods: {
    onSuccess() {
      this.succeeded = true;
    },
  },
};
</script>

<style>
</style>