<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleLogIn)">
      <b-row cols="1">
        <b-col>
          <validation-provider
            v-slot="{ failed, errors }"
            rules="required|email"
            mode="eager"
          >
            <simple-form-input
              :hasError="failed"
              :helperText="errors[0]"
              :label="$t('general.email')"
              v-model="email"
              type="email"
              data-cy="input-login"
              autocomplete="email"
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider v-slot="{ failed, errors }" rules="required">
            <simple-form-input
              :hasError="failed"
              :helperText="errors[0]"
              :label="$t('general.password')"
              v-model="password"
              type="password"
              data-cy="input-password"
              autocomplete="current-password"
            />
          </validation-provider>
        </b-col>
      </b-row>
      <b-row cols="1">
        <b-col>
          <router-link to="/forgottenPassword">{{
            $t("loginForm.forgotPassword")
          }}</router-link>
        </b-col>
        <b-col class="login-controls">
          <b-button
            type="submit"
            :disabled="isLoggingIn"
            data-cy="button-login"
          >
            <b-spinner v-if="isLoggingIn" small />
            {{ $t("general.logIn") }}
          </b-button>
          <b-button
            variant="primary"
            to="/register"
            data-cy="button-registration"
          >
            {{ $t("loginForm.register") }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MainAlert from "../alerts/MainAlert.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
export default {
  name: "login-form",
  components: { SimpleFormInput },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["logIn", "logOut"]),
    handleLogIn() {
      this.logIn({
        email: this.email,
        password: this.password,
      }).catch(() =>
        this.$toast.error({
          component: MainAlert,
          props: {
            message: this.$i18n.t("general.wrongCredentials"),
          },
        })
      );
    },
  },
  computed: {
    ...mapGetters({
      restAuthUrl: "getRestAuthUrl",
      user: "getUser",
      isLoggingIn: "getLoginStatus",
      loginError: "getLoginError",
    }),
  },
};
</script>
<style lang="scss" scoped>
.login-controls {
  .btn {
    display: block;
    margin: 0 auto;
    margin-bottom: toRem(34);
    min-width: toRem(100);
  }
  a.btn {
    width: toRem(100);
  }
}
</style>