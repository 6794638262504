<template>
  <b-nav class="vertical-nav">
    <nav-item
      to="/profile"
      :text="$t('userInfoAndSettings.sectionTitle')"
      exact
    >
      <user-icon :fillColor="ICONS_FILL_COLOR" />
    </nav-item>
  </b-nav>
</template>

<script>
import { ICONS_FILL_COLOR } from "../../utils/constants";
import NavItem from "./NavItem.vue";
import UserIcon from "vue-material-design-icons/AccountCircleOutline.vue";
export default {
  components: { NavItem, UserIcon },
  name: "profile-navigation",
  data() {
    return {
      ICONS_FILL_COLOR,
    };
  },
};
</script>

<style>
</style>