<template>
  <b-row class="center-aligning-row">
    <b-col cols="12" md="8" lg="5" v-if="!succeeded">
      <h2>{{ $t("register.sectionTitle") }}</h2>
      <div class="subtitle">
        {{ $t("register.sectionSubtitle") }}
      </div>
      <div class="free-assurance">
        <ul>
          <register-reason
            v-for="reason in reasons"
            :reason="reason"
            :key="reason"
          />
        </ul>
      </div>
      <register-form @succeeded="handleRegistrationSuccess" />
    </b-col>
    <b-col cols="12" md="8" lg="5" v-else class="success-info">
      <h2>{{ $t("register.successLine1") }}</h2>
      <p>{{ $t("register.successLine2") }}</p>
      <p>
        <b>{{ $t("register.successLine3") }}</b>
      </p>
    </b-col>
  </b-row>
</template>

<script>
import RegisterForm from "../components/forms/RegisterForm.vue";
import RegisterReason from "../components/RegisterReason.vue";
export default {
  components: { RegisterForm, RegisterReason },
  name: "registration-page",
  data() {
    return {
      succeeded: false,
      reasons: [
        this.$i18n.t("register.reasons.r2"),
        this.$i18n.t("register.reasons.r3"),
        this.$i18n.t("register.reasons.r4"),
      ],
    };
  },
  methods: {
    handleRegistrationSuccess() {
      this.succeeded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.free-assurance {
  border: 1px solid $blueBorders;
  border-radius: $navItemRadius;
  margin-bottom: toRem(20);
  padding: 1rem;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.subtitle {
  text-align: center;
  margin-bottom: toRem(20);
}

</style>