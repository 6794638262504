var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"registrationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleRegister)}}},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|email","mode":"eager","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"hasError":failed,"helperText":errors[0],"label":_vm.$t('general.email'),"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":{
            required: true,
            min: 8,
            regex: _vm.PASSWORD_REGEX,
          },"name":"password1","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
          var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"hasError":failed,"helperText":errors[0],"label":_vm.$t('general.password'),"type":"password"},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"min:8|required|password:@password1","name":"password2","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var failed = ref.failed;
return [_c('simple-form-input',{attrs:{"type":"password","label":_vm.$t('registrationForm.password2Label'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"register-checkbox",attrs:{"required":"","state":errors.length !== 0 ? false : null},model:{value:(_vm.termsAgreed),callback:function ($$v) {_vm.termsAgreed=$$v},expression:"termsAgreed"}},[_vm._v(" "+_vm._s(_vm.$t("registrationForm.IAgreeWith"))+" "),_c('router-link',{attrs:{"to":"/termsAndConditions","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("registrationForm.TOC")))]),_vm._v(" "+_vm._s(_vm.$t("registrationForm.and"))+" "),_c('router-link',{attrs:{"to":"/privacyPolicy","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("registrationForm.privacyPolicy"))+".")])],1)]}}],null,true)})],1)],1),_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"type":"submit","disabled":_vm.isRegistering,"id":"registration-button"}},[(_vm.isRegistering)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.$t("registrationForm.registerButton"))+" ")])],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isRegistering,"to":"/"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.logIn"))+" ")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }