<template>
  <landing-layout>
  <b-row class="center-aligning-row">
    <b-col cols="12" md="8" lg="5">
      <h2>{{ $t("loginForm.sectionTitle") }}</h2>
      <login-form />
    </b-col>
  </b-row>
  </landing-layout>
</template>

<script>
import LoginForm from "../components/forms/LoginForm.vue";
export default {
  components: { LoginForm },
  name: "Login",
};
</script>

<style>
</style>